import * as React from "react"

import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../templates/layout'

const Solutions = () => {

  const frontmatterData = useStaticQuery(graphql`
  query MyQuery {
    allMdx {
      nodes {
        frontmatter {
          slug
          title
        }
      }
    }
  }
`)

  const frontMatter = frontmatterData.allMdx.nodes;

  console.log({frontMatter})

  return (
    <>

      <Layout>
        <main>
          <title>Solutions</title>
          <h1>Solutions</h1>

          <div className='solutionsContainer'>

            {/* {frontMatter.map((post) => {
              if (post.frontmatter.slug !== null) {
                return (
                  <Link
                    key={post.frontmatter.slug}
                    to={`csec/physics/`}
                    className='button'
                  >
                    {post.frontmatter.title}
                  </Link>
                )
              }
            })
            } */}
            <Link to="csec/physics/CSEC-Physics-May-June-2005-Paper-2" className="button">May/June 2005 Paper 2</Link>
            <Link to="csec/physics/CSEC-Physics-May-June-2005-Paper-2" className="button">May/June 2006 Paper 2</Link>

          </div>

        </main>
      </Layout>
    </>
  )
}

export default Solutions
